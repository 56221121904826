<template>
    <TwentyTwenty :before="before" :after="after" :beforeLabel="beforeLabel" :afterLabel="afterLabel"/>
</template>
<script>
// import 'vue-twentytwenty/dist/vue-twentytwenty.css';
import TwentyTwenty from 'vue-twentytwenty';

export default {
    // ...
    components: {
        TwentyTwenty
    },
    props: [
        "before",
        "after"
    ],
    data(){
        return {
            beforeLabel: 'До',
            afterLabel: 'После'
        }
    }
    // ...
};
</script>
